/** @jsx jsx */
import {Box,jsx,Grid, Heading,Text,Link as ThLink} from 'theme-ui'
//import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'
import {GatsbyImage} from 'gatsby-plugin-image'
import { graphql,Link } from 'gatsby'





export default function ZweiterSalon({data}){
 
return(
  <Layout>
    <Heading as="h1" sx={{
                     fontWeight:500,
                     fontSize:[2,3],
                     mt:4
                 }}> Salon N° 2</Heading>
           <Link sx={{color:'text',display:'block', mb:30}} to="/allesalons/bilder-salon2">&rarr; Text und Bilder zum Konzert</Link>
   
     <MySEO
     title="Der zweite Salon von K.0.M in Kaub"
     
    />

     

<Box style={{maxWidth:500}}>
    <Grid columns={[1,2,2]} >
   
  <div  mb="4">
     
     <Text  variant="heading">Ort</Text><br/>
     <Text>Blüchermuseum<br/>
     56349 Kaub<br/>
     Metzgergasse 6<br/>
     </Text>  
     <Text variant="heading">Datum/Uhrzeit:</Text>
      <Text>3.Oktober, 11:00 Uhr</Text>
     
     
    
  </div>
  <div>
  <Text variant="heading">Ausführende</Text>

    <Text color="text">
    Annette Seyfried, Violine<br/>
		Clara Holdenried, Viola<br/>
		Emma Mühlnickel, Violine<br/>
   
    </Text>
    </div>
    
    </Grid>
    </Box>
     {/* <Text color="text"> Webseite: www.streicherakademie-mainz.de</Text> */}
    <Box color="text" pt="30px">
     <Text variant="titel">Das Programm</Text>
     
    
  <Heading variant="heading">
  Kuriositäten in kleiner Kammermusikbesetzung
  </Heading>


<Box style={{maxWidth:900}}>
<Text>
Mozart schreibt für Michael Haydn inkognito zwei Duos für Violine und Viola.
<br/>
 Johan Halvorsen nimmt ein Thema von Händel und schreibt darüber eine hoch virtuose Passacaglia.
 <br/>
   Dvorak berichtet seinem Verleger Simrock: „Ich schreibe jetzt kleine Bagatellen, denken Sie, nur für zwei Violinen und Viola. Die Arbeit freut mich ebenso sehr, als wenn ich eine große Sinfonie schriebe.“ 
</Text>
<Text>
Ein Programm mit außergewöhnlichen Werken von <strong>Mozart, Händel/Halvorsen und Dvorak</strong>, wunderschönen und berührenden Melodien, virtuos und zugleich tiefsinnig. 

</Text>
</Box>
<p sx={{color:'text',marginTop:20,paddingLeft:2,borderLeft:'red solid 20px'}}>
 Der Eintritt ist frei.<br/>
<u>Um Anmeldung wird per Email gebeten</u>:<br/>
kontakt@kulturnetz-oberes-mittelrheintal.org
</p>
</Box>

  
   
 




<Text sx={{fontSize:"26px"}} color="text" mt="20px">Blüchermuseum in Kaub</Text>
<Box style={{paddingBottom:50}}>
<Grid columns={[1,2]}>
 
    <Box>

    <GatsbyImage image={data.eingang.childImageSharp.gatsbyImageData} alt="Konzertimpression" />
{/* <p sx={{color:"text",lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Historischer Flügel im Blüchermuseum</p> */}

 <GatsbyImage image={data.salonzwei1.childImageSharp.gatsbyImageData} alt="Eingang Veranstaltungsort" /> 
 {/* <p sx={{color:"text",lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Blüchermuseum in der Metzgergasse</p> */}
</Box>
<Box>
<GatsbyImage image={data.salonzwei2.childImageSharp.gatsbyImageData} alt="Raum im Blüchermuseum" />

{/* <p sx={{color:"text",lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Blüchermuseum</p> */}


<GatsbyImage image={data.fluegel.childImageSharp.gatsbyImageData} alt="Konzertimpression" />
{/* <p sx={{color:"text",lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Historischer Flügel im Blüchermuseum</p> */}



</Box>
<Link to ="/salons">&rarr; Salons <ThLink>&rarr; Salons</ThLink></Link>
    </Grid>
   
    </Box>
    {/* <p>&rarr; <a sx={{color:'text'}} rel="noopener noreferrer" target="_blank" href="pdf/KOM_Salon2.pdf">Flyer zum Salon 2 / Download</a></p> */}
    
    </Layout>
)
}


export const ZweiterSalonQuery = graphql`
  query {
    
    salonzwei1:file(relativePath: { eq: "salons/museum-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    salonzwei2:file(relativePath: { eq: "salons/museum-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
      
    }
    eingang:file(relativePath: { eq: "salons/museum-3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
      
    }
    salonzwei3:file(relativePath: { eq: "salons/museum-3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
      emma:file(relativePath: { eq: "salons/emmamuehlnickel.jpg" }) {
        childImageSharp {
        
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }

      clara:file(relativePath: { eq: "salons/holdenried.jpg" }) {
        childImageSharp {
        
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      } 
      annette:file(relativePath: { eq: "salons/annetteseyfried.jpg" }) {
        childImageSharp {
        
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }   
      fluegel:file(relativePath: { eq: "salons/bluechermuseumfluegel.jpg" }) {
        childImageSharp {
        
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }   

  }
`